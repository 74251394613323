.card-header .title {
    font-size: 17px;
    color: gray;
}
.card-header .accicon {
    float: right;
    font-size: 20px;
    width: 1.2em;
}
.card-header{
    cursor: pointer;
    border-bottom: none;
    padding: 5px 10px;
}
.card{
    border: 1px solid #ddd;
}
.card-body{
    border-top: 1px solid #ddd;
}
.card-header:not(.collapsed) .rotate-icon {
    transform: rotate(180deg);
}
